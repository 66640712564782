exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-accessible-medicine-mdx": () => import("./../../../src/pages/blog/accessible-medicine.mdx" /* webpackChunkName: "component---src-pages-blog-accessible-medicine-mdx" */),
  "component---src-pages-blog-cubes-vs-nats-mdx": () => import("./../../../src/pages/blog/cubes-vs-nats.mdx" /* webpackChunkName: "component---src-pages-blog-cubes-vs-nats-mdx" */),
  "component---src-pages-blog-faq-mdx": () => import("./../../../src/pages/blog/faq.mdx" /* webpackChunkName: "component---src-pages-blog-faq-mdx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-intro-to-microdosing-mdx": () => import("./../../../src/pages/blog/intro-to-microdosing.mdx" /* webpackChunkName: "component---src-pages-blog-intro-to-microdosing-mdx" */),
  "component---src-pages-blog-microdosing-research-2024-mdx": () => import("./../../../src/pages/blog/microdosing-research-2024.mdx" /* webpackChunkName: "component---src-pages-blog-microdosing-research-2024-mdx" */),
  "component---src-pages-blog-solo-journey-preparation-mdx": () => import("./../../../src/pages/blog/solo-journey-preparation.mdx" /* webpackChunkName: "component---src-pages-blog-solo-journey-preparation-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

